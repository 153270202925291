<template>
  <div>
    <AppModal
      class="signup-modal"
      :disableClose="true">
      <div class="content">
        <div v-if="!success">
          <p class="title">Sign Up for Email Updates</p>
          <b-form @submit.prevent="signup">
            <b-form-group>
              <b-form-input
                type="text"
                placeholder="Name"
                autocomplete="off"
                aria-describedby="name-input"
                v-model.trim="$v.form.name.$model"
                :state="!$v.form.name.$error"
                @input="$v.form.name.$touch()"
                size="sm"
              ></b-form-input>
              <b-form-invalid-feedback id="name-input" class="text-left">
                <span v-if="!$v.form.name.required">
                  Name is required
                </span>
                <span v-if="!$v.form.name.minLength && $v.form.name.required">
                  Name must have at least
                  {{$v.form.name.$params.minLength.min}} characters
                </span>
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group>
              <b-form-input
                type="email"
                placeholder="Email Address"
                autocomplete="off"
                aria-describedby="email-input"
                v-model.trim="$v.form.email.$model"
                :state="!$v.form.email.$error"
                @input="$v.form.email.$touch()"
                size="sm"
              ></b-form-input>
              <b-form-invalid-feedback id="email-input" class="text-left">
                <span v-if="!$v.form.email.required">
                  Email Address is required
                </span>
                <span v-if="!$v.form.email.email && $v.form.email.required">
                  Email Address is not valid
                </span>
                <span v-if="!$v.form.email.uniqueEmail && $v.form.email.email &&
                  $v.form.email.required">
                  Email Address is already added
                </span>
              </b-form-invalid-feedback>
            </b-form-group>

            <b-row class="pt-3">
              <b-col>
                <b-button
                  class="btn-h3-green"
                  type="submit"
                  variant="success"
                  :disabled="$v.form.$invalid"
                  block>
                  Submit
                </b-button>
              </b-col>
              <b-col>
                <b-button
                  class="btn-h3-red"
                  type="button"
                  variant="danger"
                  @click="$emit('close')"
                  block>
                  Cancel
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </div>

        <p class="success" v-else>
          You've been added to our mailing list!
        </p>
      </div>
    </AppModal>

    <AppLoader :isLoading="loading" />
  </div>
</template>

<script>
  import { required, minLength, email } from 'vuelidate/lib/validators';
  
  export default {
    data() {
      return {
        loading : false,
        success : false,
        form    : {
          name  : null,
          email : null,
        },
        uniqueEmail : true,
      }
    },
    components : {
      'AppModal'  : () => import('@/components/layout/AppModal'),
      'AppLoader' : () => import('@/components/layout/AppLoader'),
    },
    watch : {
      'form.email'() {
        this.uniqueEmail = true;
      },
    },
    methods : {
      signup() {
        this.loading = true;
        this.$http.post('api/mailing_list', this.form)
          .then(() => {
            this.success = true;
            this.form.name = null;
            this.form.email = null;
            this.$v.$reset();

            setTimeout(() => { this.$emit('close'); }, 3000);
          })
          .catch(err => {
            if (err.response.data.code === '0001')
              this.uniqueEmail = false;
            else {
              this.$notify({
                group : 'notif',
                type  : 'error',
                title : 'Failed',
                text  : 'Oops! Something went wrong!',
              });
            }
          })
          .finally(() => {
            this.loading = false;
          });
      },
    },
    validations : {
      form : {
        name : {
          required,
          minLength : minLength(2),
        },
        email : {
          required,
          email,
          uniqueEmail() {
            return this.uniqueEmail;
          },
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  .signup-modal {
    padding-bottom: 0 !important;
    background-image: $slide-2;
    background-size: cover;
  }

  .title {
    color: #fff !important;
    font-size: 1.5em !important;
    letter-spacing: 0;
    text-align: center;
    text-shadow: 1px 1px 3px rgba(0,0,0,0.8) !important;
    -webkit-text-stroke: 1px #000 !important;
  }

  .success {
    color: #fff !important;
    font-size: 1.5em !important;
    letter-spacing: 0;
    line-height: 1;
    text-align: center;
    text-shadow: 1px 1px 2px rgba(40, 167, 69, 0.8) !important;
    -webkit-text-stroke: 1px rgb(40, 167, 69) !important;
  }

  .content {
    padding: 1rem 3rem;
  }

  .invalid-feedback {
    font-weight: bold;
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {
    .content {
      padding: 1rem;
    }
  }
</style>