<template>
  <div id="maintenance-page">
    <div class="maintenance-content text-center">
      <b-row
        class="justify-content-center"
        align-v="center"
        no-gutters>
        <b-col cols="6" xl="3" order="2" order-xl="1">
          <img
            class="img-fluid pj"
            src="../../assets/images/maintenance/PJ.png"
            alt="PJ Panda"
          />
        </b-col>
        <b-col cols="12" xl="5" order="1" order-xl="2">
          <h2 class="title">FOR THE CHILDREN<br>FOR THE CULTURE</h2>
          <h3 class="sub-title pb-3">
            NEW WEBSITE MOBILE<br>APP LAUNCHING THIS FALL
          </h3>

          <b-button
            class="signup-btn pt-2 pb-2"
            @click="openSignup">
            SIGN UP
          </b-button>

          <h3 class="sub-title pt-3 pb-2">
            SIGN UP FOR EMAIL UPDATES
          </h3>

          <img
            class="h3-logo mb-3"
            src="../../assets/images/maintenance/h3-logo-outline.png"
            alt="Healthy Hip Hop Logo"
          />
        </b-col>
        <b-col cols="6" xl="3" order="3" order-xl="3">
          <img
            class="img-fluid leo"
            src="../../assets/images/maintenance/Leo.png"
            alt="Leo Lion"
          />
        </b-col>
      </b-row>
    </div>

    <modals-container />
  </div>
</template>

<script>
  import SignupModal from './SignupModal';

  export default {
    head : {
      title : {
        inner      : 'Maintenance',
        separator  : '|',
        complement : 'Healthy Hiphop',
      },
    },
    methods : {

      /**
       * Open Signup Modal
       */
      openSignup() {
        this.$modal.show(SignupModal, {}, {
          height       : 'auto',
          adaptive     : true,
          classes      : 'maint-signup-modal',
          clickToClose : false,
        });
      },
    },
    mounted() {
      if (this.$store.getters['web/isMaintenance'] === false ||
        localStorage.maintenance === 'false')
        this.$router.replace('/');
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  #maintenance-page {
    min-height: 100vh;
    background-image: $section1-bg;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .maintenance-content {
    margin: 0 auto;
    padding: 2rem 0;
    max-width: 80%;
  }

  .title {
    margin-bottom: 2rem;
    letter-spacing: 0;
    text-shadow: 1px 1px 5px rgba(0,0,0,0.5) !important;
  }
  .sub-title {
    letter-spacing: 0;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5) !important;
  }

  .signup-btn {
    padding: 0.35rem 4rem;
    background-color: #cf8401;
    border: 0.25rem solid #915e03;
    border-radius: 1rem;
    font-family: 'WickedMouse' !important;
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {
    .maintenance-content {
      max-width: 90%;
    }
    .title {
      font-size: 1.5em !important;
      -webkit-text-stroke: 1.5px #000 !important;
    }
    .sub-title {
      font-size: 1.1em !important;
      -webkit-text-stroke: 1.1px #000 !important;
    }
    .h3-logo {
      width: 10rem;
    }
    .pj, .leo {
      width: 80%;
    }
  }
  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    .title {
      font-size: 1.8em !important;
      -webkit-text-stroke: 1.5px #000 !important;
    }
    .sub-title {
      font-size: 1.35em !important;
      -webkit-text-stroke: 1.1px #000 !important;
    }
    .signup-btn {
      font-size: 1.2em !important;
    }
    .h3-logo {
      width: 13rem;
    }
  }
  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    .pj, .leo {
      width: 80%;
    }
  }
  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {
    .title {
      margin-bottom: 3rem;
      font-size: 2.2em !important;
      -webkit-text-stroke: 1.5px #000 !important;
    }
    .sub-title {
      font-size: 1.3em !important;
      -webkit-text-stroke: 1.5px #000 !important;
    }
    .signup-btn {
      font-size: 1.5em !important;
    }
    .h3-logo {
      width: 14rem;
    }
  }
  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    .title {
      margin-top: 5rem;
    }
    .pj, .leo {
      width: 100%;
    }
  }
  @media only screen and (min-width: $special) {}
  @media only screen and (min-width: $xl2-min) {
    .title {
      margin-top: 2.5rem;
      margin-bottom: 3rem;
      -webkit-text-stroke: 1.75px #000 !important;
    }
    .sub-title {
      font-size: 1.3em !important;
      -webkit-text-stroke: 1.75px #000 !important;
    }
    .signup-btn {
      font-size: 1.3em !important;
    }
  }
  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {}
</style>